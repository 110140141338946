import hotstarNewLogo from '@assets/images/hotstar-new.png';
import { closePopup, openPopup } from '@common/Modal/action';
import { MODALS } from '@common/Modal/constants';
import { openLoginPopup } from '@containers/Login/APIs/actions';
import { fetchAccountRecoveryRequest, getTVODExpiry, fetchAccountRecoveryUrl } from '@containers/PIDetail/API/actions';
import {
    getZee5Tag,
    setContinueWatching,
    setLA,
} from '@containers/PlayerWeb/APIs/actions';
import { hideMainLoader, showMainLoader, fromLoginLoader } from '@src/action';
import {
    checkPartnerPlayable,
    isMobile,
    isUserloggedIn,
    playContent,
    playContentEventTrack,
    deeplinkToPIPage,
    setLALogic,
    providerImage,
    getProviderDisplayName,
    cloudinaryCarousalUrl,
    pollApi,
    amazonTryAgainModal
} from '@utils/common';
import { CONTENTTYPE, LOCALSTORAGE, PARTNER_SUBSCRIPTION_TYPE, FORMATTED_CONTENT_TYPE, LAYOUT_TYPE } from '@utils/constants';
import { getKey, setKey } from '@utils/storage';
import { get, isEmpty } from 'lodash';
import { useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MIXPANEL from '@constants/mixpanel';
import MOENGAGE from '@constants/moengage';
import { checkForNonIntegratedPartner } from "@containers/PIDetail/PIDetailCommon";
import useContentAnalytics from './useContentAnalytics';
import { useParams, useLocation, useHistory } from "react-router-dom";
import store from '@src/store';
import { PROVIDER_NAME } from '@utils/constants/playerConstant';

export default function useContentPlay({
    onPlay,
    contentType,
    sectionData = {
        railTitle: '',
        source: '',
        origin: '',
        railPosition: '',
        configType: '',
        sectionType: '',
        contentPosition: '',
    },
}) {
    const partnerUniqueIdInfo = useSelector((state) => state?.subscriptionDetails?.currentSubscription?.data?.partnerUniqueIdInfo, shallowEqual);
    const lastWatch = useSelector(state => state?.PIDetails?.continueWatchingDetails?.data, shallowEqual) || {};
    const getTVODExpiryTime = useSelector(state => state?.PIDetails?.tvodExpiryDetails?.data?.purchaseExpiry, shallowEqual)
    const seriesList = useSelector(state => state?.PIDetails?.data?.seriesList, shallowEqual)
    const history = useHistory();
    const dispatch = useDispatch();
    let params = useParams();
    const location = useLocation();

    const mixpanelData = {
        railTitle: location?.state?.railTitle,
        source: location?.state?.source,
        origin: location?.state?.prevPath,
        railPosition: location?.state?.railPosition,
        conPosition: location?.state?.conPosition,
        sectionSource: location?.state?.sectionSource,
        configType: location?.state?.configType,
        sectionType: location?.state?.sectionType,
        contentSectionSource: location?.state?.contentSectionSource,
    };

    const contentRef = useRef({
        meta: {},
    });

    const { trackMixPanelEvent, trackMongageEvent, zee5TagMixpanelEvents, trackPrimeMixpanel } = useContentAnalytics({
        ...sectionData,
    });

    const partnerErrorPopup = useCallback(() => {
        dispatch(closePopup());
        toast('Error. Content not found!', {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    }, [dispatch]);

    const setCWForPartner = useCallback(async (useCWData = false, cwData) => {
        const { meta, match } = contentRef.current;
        let updatedContentType = FORMATTED_CONTENT_TYPE[match?.params?.contentType];

        if (useCWData) {
            await dispatch(
                setContinueWatching(
                    cwData.id,
                    1,
                    cwData.totalDuration,
                    cwData.contentType
                )
            );
        } else if (
            updatedContentType === CONTENTTYPE.TYPE_BRAND ||
            updatedContentType === CONTENTTYPE.TYPE_TV_SHOWS ||
            updatedContentType === CONTENTTYPE.TYPE_SERIES
        ) {
            let seriesContentType = meta.vodContentType
                ? meta.vodContentType
                : meta.contentType, id;
            if (meta?.isEpisodeContent) {
                id = meta.id;
            } else {
                id = lastWatch?.vodId ? lastWatch.vodId : meta.vodId || meta.id;
            }
            await dispatch(
                setContinueWatching(
                    id,
                    1,
                    meta?.duration || meta?.totalDuration,
                    seriesContentType
                )
            );
        } else {
            await dispatch(
                setContinueWatching(
                    meta?.vodId || meta?.id,
                    1,
                    meta?.duration || meta?.totalDuration,
                    updatedContentType
                )
            );
        }
    }, [dispatch, lastWatch.contentType, lastWatch.id, params]);

    const zee5MixpanelEvents = (tag) => {
        const { meta } = contentRef.current;
        let data = { ...contentRef?.current, tag: tag };
        trackMixPanelEvent(data, MIXPANEL.EVENT.PLAY_CONTENT);
        playContentEventTrack(meta);
    }

    const zee5Redirection = useCallback(async (partnerWebUrl, tag, useCWData, cwData, learnData) => {
        let data = { ...contentRef?.current, tag: tag };
        await setCWForPartner(useCWData, cwData);
        await setLALogic(learnData?.id) && await store.dispatch(setLA(learnData))
        setKey(LOCALSTORAGE.DEEPLINK, JSON.stringify(true));

        zee5MixpanelEvents(tag);
        tag && zee5TagMixpanelEvents(data, tag)
        let partnerUrl = partnerWebUrl?.split("?")
        let constUrl = "utm_source=tataskybinge&utm_medium=amazonstick&utm_campaign=zee5campaign&partner=tataskybinge"
        let newPartnerUrl = `${partnerUrl[0]}?${constUrl}`
        window.location.href = `${newPartnerUrl}&tag=${tag}`;
    }, [setCWForPartner, trackMixPanelEvent, trackMongageEvent, zee5TagMixpanelEvents]);

    const playZee5Content = useCallback(async (partnerWebUrl, useCWData, cwData, learnData) => {
        const { meta } = contentRef.current;
        const isFreemium =
            meta?.partnerSubscriptionType?.toUpperCase() !==
            PARTNER_SUBSCRIPTION_TYPE.PREMIUM;
        if (partnerWebUrl) {
            let partnerUniqueId;
            let partnerPlayable = isUserloggedIn() && await checkPartnerPlayable(meta?.partnerId, meta?.provider);
            if (((!isFreemium || partnerPlayable) && !isEmpty(partnerUniqueIdInfo))) {
                partnerUniqueId = partnerUniqueIdInfo[meta.provider.toUpperCase()]?.partnerUniqueId;
                partnerUniqueId = !isEmpty(partnerUniqueId) ? partnerUniqueId : '';

                showMainLoader();
                await dispatch(getZee5Tag(partnerUniqueId)).then(async (response) => {
                    dispatch(hideMainLoader());
                    if (isEmpty(response.data)) {
                        let data = { ...contentRef.current, partnerUniqueId, response };
                        zee5TagMixpanelEvents(data)
                        partnerErrorPopup(response);
                    } else {
                        const tag = response?.data?.tag;
                        await zee5Redirection(partnerWebUrl, tag, useCWData, cwData, learnData)
                    }
                })
                    .catch((error) => {
                        let data = { ...contentRef.current, partnerUniqueId, error }
                        zee5TagMixpanelEvents(data)
                        partnerErrorPopup();
                    });
            } else {
                await zee5Redirection(partnerWebUrl, '', useCWData, cwData, learnData);
            }
        } else {
            partnerErrorPopup();
        }
    }, [partnerUniqueIdInfo, dispatch, partnerErrorPopup, zee5Redirection]);

    const reDirectToHotstar = async (hotstarWebDeeplink, setCW, learnData, useCWData, cwData) => {
        const { meta } = contentRef.current;
        if (hotstarWebDeeplink) {
            setCW && (await setCWForPartner(useCWData, cwData));
            await setLALogic(learnData?.id) && await store.dispatch(setLA(learnData))
            setKey(LOCALSTORAGE.DEEPLINK, JSON.stringify(true));
            trackMixPanelEvent(
                contentRef?.current,
                MIXPANEL.EVENT.PLAY_CONTENT
            );
            trackMongageEvent(
                contentRef?.current,
                MOENGAGE.EVENT.PLAY_CONTENT
            );
            playContentEventTrack(meta);
            window.location.href = hotstarWebDeeplink;
        } else {
            partnerErrorPopup();
        }
    }

    const playHotStarContent = useCallback((hotstarWebDeeplink, setCW, ispopUp, learnData, useCWData, cwData) => {
        const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        const rmn = get(userInfo, 'rmn');

        const state = store.getState(),
            configResponse = get(state.headerDetails, 'configResponse'),
            hotstarPopUpVerbiages = get(configResponse.data.config, 'hotstarPopUp.web'),
            hotsarlogo = providerImage(PROVIDER_NAME.HOTSTAR, LAYOUT_TYPE.SQUARE, true);

        if (ispopUp) {
            dispatch(
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: 'alert-modal hotstar',
                    instructions: `<img src=${hotsarlogo} alt='' height="90" width="90"/>
                            <p class='primary-hotstar-text'>${get(hotstarPopUpVerbiages, 'appInstalledVerbiage', "You will be redirected to JioHotstar")}</p>
                            <p class='secondary-hotstar-text'> ${get(hotstarPopUpVerbiages, 'appNotInstalledVerbiageSubtext', "Please ensure you are logged in with your Tata Play Registered Mobile Number")} ${rmn}</p>`,
                    primaryButtonText: get(hotstarPopUpVerbiages, 'appInstalledPrimaryCTA', "Proceed"),
                    isHtml: true,
                    primaryButtonAction: async () => {
                        await redirectionHandling(hotstarWebDeeplink, setCW, learnData, useCWData, cwData);
                        dispatch(closePopup());
                    },
                    secondaryButtonText: `${isMobile.any() ? get(hotstarPopUpVerbiages, 'appInstalledSecondaryCTA', "Cancel") : ''}`,
                    secondaryButtonAction: () => {
                        closePopup();
                    },
                    hideCloseIcon: isMobile.any() ? true : false
                })
            );
        } else {
            reDirectToHotstar(hotstarWebDeeplink, setCW, learnData, useCWData, cwData)
        }
    }, [dispatch, partnerErrorPopup, setCWForPartner, trackMixPanelEvent, trackMongageEvent]);

    const redirectionHandling = async (partnerDeepLinkUrl, setCW, learnData, useCWData, cwData, isPrime = false) => {
        if (partnerDeepLinkUrl) {
            const { meta } = contentRef.current;
            setCW && (await setCWForPartner(useCWData, cwData));
            await setLALogic(learnData?.id) && await store.dispatch(setLA(learnData))
            setKey(LOCALSTORAGE.DEEPLINK, JSON.stringify(true));
            //For apple tv content play event will be fired on play button click only and not on redirection
            let isApple = meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE;
            !isApple && trackMixPanelEvent(
                contentRef?.current,
                MIXPANEL.EVENT.PLAY_CONTENT,
                isApple,
            );
            isPrime && trackPrimeMixpanel(MIXPANEL.EVENT.PRIME_REDIRECTION_POPUP_PROCEED)
            playContentEventTrack(meta);
            window.location.href = partnerDeepLinkUrl;
        } else {
            partnerErrorPopup();
        }
    }

    const deepLinkContent = (message) => {
        dispatch(
            openPopup(MODALS.ALERT_MODAL, {
                modalClass: 'alert-modal vootplay',
                instructions: `
                <p class='primary-sonyLiv-text'>${message}</p>`,
                primaryButtonText: `${isMobile.any() ? 'Go to app' : 'Ok'}`,
                isHtml: true,
                primaryButtonAction: async () => {
                    if (isMobile.any()) {
                        deeplinkToPIPage();
                    }
                },
                secondaryButtonText: "Cancel",
                secondaryButtonAction: () => {
                    closePopup()
                },
            })
        );
    }

    const hotstarPopup = async (hotstarWebDeeplink, setCW, learnData) => {
        if (hotstarWebDeeplink) {
            const { meta } = contentRef.current;
            setCW && (await setCWForPartner());
            await setLALogic(learnData?.id) && await store.dispatch(setLA(learnData))
            setKey(LOCALSTORAGE.DEEPLINK, JSON.stringify(true));
            trackMixPanelEvent(
                contentRef?.current,
                MIXPANEL.EVENT.PLAY_CONTENT
            );
            trackMongageEvent(
                contentRef?.current,
                MOENGAGE.EVENT.PLAY_CONTENT
            );
            playContentEventTrack(meta);
            window.location.href = hotstarWebDeeplink;
        } else {
            partnerErrorPopup();
        }
    }


    const tvodExpiry = useCallback(async () => {
        const { meta } = contentRef.current;
        if (!meta?.showcase && !meta?.isPlaybackStarted) {
            meta?.vodId && (await dispatch(getTVODExpiry(meta?.vodId, true)));
            const result = meta.id.split('?');
            const tvodInfo = JSON.parse(getKey(LOCALSTORAGE.TVOD_DATA));
            const data = tvodInfo && tvodInfo?.find((i) => i.id === parseInt(result[0]));

            if (data && getTVODExpiryTime) {
                const index = tvodInfo && tvodInfo?.findIndex((i) => i.id === parseInt(result[0]));
                tvodInfo[index].rentalExpiry = this.props.getTVODExpiryTime;
                setKey(LOCALSTORAGE.TVOD_DATA, tvodInfo);
            }
        }
    }, [dispatch, getTVODExpiryTime]);

    const playPrimeDeeplink = useCallback((primeWebDeeplink, setCW, ispopUp, learnData, useCWData, cwData, nudgePlayback) => {
        const state = store.getState();
        let amazonPopUpVerbiages = state.subscriptionDetails?.myPlanVerbiages?.amazonSubscriptionVerbiage?.amazonRedirectionConfirmationPopup;
        if (ispopUp) {
            dispatch(
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: `alert-modal amazon_popup`,
                    headingMessage: amazonPopUpVerbiages?.title,
                    instructions: nudgePlayback ? amazonPopUpVerbiages?.existingUserSubtitle : amazonPopUpVerbiages?.subTitle,
                    primaryButtonText: amazonPopUpVerbiages?.primaryCTA,
                    imageUrl: amazonPopUpVerbiages?.image,
                    primaryButtonAction: async () => {
                        await redirectionHandling(primeWebDeeplink, setCW, learnData, useCWData, cwData, true);
                        dispatch(closePopup());
                    },
                    secondaryButtonText: !nudgePlayback && amazonPopUpVerbiages?.accountRecoveryCTA,
                    secondaryButtonAction: () => {
                        handleForgetAccount()
                        trackPrimeMixpanel(MIXPANEL.EVENT.PRIME_REDIRECTION_POPUP_FORGOTACCOUNT)
                    },
                    hideCloseIcon: false,
                    isCloseModal: false,
                }),
            );
            trackPrimeMixpanel(MIXPANEL.EVENT.PRIME_REDIRECTION_POPUP)
        } else {
            redirectionHandling(primeWebDeeplink, setCW, learnData, useCWData, cwData);
        }
    }, [dispatch, partnerErrorPopup, setCWForPartner]);

    const handleGenericRedirectionPopup = useCallback((redirectionDeepLinkUrl, ispopUp, learnData, useCWData, cwData) => {
        const { meta } = contentRef.current;

        if (ispopUp) {
            const state = store.getState();
            let myPlanVerbiagesResponse = get(state.subscriptionDetails, "myPlanVerbiages", {}),
                partnerSubscriptionVerbiage = get(myPlanVerbiagesResponse, "partnerSubscriptionVerbiage.partnerRedirectionPopupDTO");
            let url = providerImage(meta?.provider, LAYOUT_TYPE.SQUARE, true),
                userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
                rmn = get(userInfo, 'rmn'),
                primaryText = partnerSubscriptionVerbiage?.title || "",
                secondaryText = partnerSubscriptionVerbiage?.subTitle?.concat(" ", rmn),
                providerName = getProviderDisplayName(meta?.provider),
                imageUrl = `${cloudinaryCarousalUrl(
                    false,
                    "",
                    90 * 2,
                    90 * 2,
                    !!isMobile.any(),
                )}${url}`;

            dispatch(
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: 'alert-modal redirection-popup',
                    instructions: `<img src=${imageUrl} alt='' height="90" width="90"/>
                    <p class='primary-hotstar-text'>${primaryText?.replace("[partner]", providerName)}</p>
                    <p class='secondary-hotstar-text'>${secondaryText?.replace("[partner]", providerName)}</p>`,
                    isHtml: true,
                    primaryButtonText: partnerSubscriptionVerbiage?.primaryCTA || "Proceed",
                    primaryButtonAction: async () => {
                        await redirectionHandling(redirectionDeepLinkUrl, true, learnData, useCWData, cwData);
                        dispatch(closePopup());
                    },
                })
            );
        } else {
            redirectionHandling(redirectionDeepLinkUrl, true, learnData, useCWData, cwData);
        }
    }, [dispatch, partnerErrorPopup, setCWForPartner, trackMixPanelEvent, trackMongageEvent]);

    const handleForgetAccount = async (callCount = 0, isPollingFailed = false) => {
        const state = store.getState();

        if (!isPollingFailed) {
            store.dispatch(fromLoginLoader(true))
            await store.dispatch(fetchAccountRecoveryRequest()).then((response) => {
                redirectionPolling()
            }).catch(e => {
                store.dispatch(fromLoginLoader(false))
                amazonTryAgainModal(handleForgetAccount, callCount + 1, false, playPrimeDeeplink, trackPrimeMixpanel)
            })
        } else {
            store.dispatch(fromLoginLoader(true))
            redirectionPolling()
        }

        async function redirectionPolling() {
            let configResponse = get(state.headerDetails, 'configResponse');
            let primeCallBackArray = get(configResponse.data.config, 'primeAccountCallbackArrayInfo.primeAccountStatusArray', [10000, 10000]);
            let iserror = false
            for (let i = 0; i < primeCallBackArray.length; i++) {
                let isLastIteration = (i + 1) === primeCallBackArray.length;

                if (iserror) {
                    break
                }
                const delay = primeCallBackArray[i];
                await new Promise(resolve => setTimeout(resolve, delay));

                await store.dispatch(fetchAccountRecoveryUrl()).then(response => {
                    if (response?.data?.recovery_url) {
                        store.dispatch(fromLoginLoader(false))
                        window.location.href = response?.data?.recovery_url;
                        return;
                    }
                    if (isLastIteration && !response?.data?.recovery_url) {
                        store.dispatch(fromLoginLoader(false))
                        amazonTryAgainModal(handleForgetAccount, callCount + 1, true, playPrimeDeeplink, trackPrimeMixpanel)
                    }
                }).catch(e => {
                    store.dispatch(fromLoginLoader(false))
                    amazonTryAgainModal(handleForgetAccount, callCount + 1, true, playPrimeDeeplink, trackPrimeMixpanel)
                    iserror = true
                    return
                })

            }
        }
    }

    return useCallback((meta, options = {}) => {
        contentRef.current = { meta, ...options };
        let seriesType = [CONTENTTYPE.TYPE_BRAND, CONTENTTYPE.TYPE_TV_SHOWS, CONTENTTYPE.TYPE_SERIES].includes(meta.contentType) ? 'seriesType' : 'movieType';
        playContent(
            {
                meta,
                history,
                checkForNonIntegratedPartner,
                contentType,
                id: meta.id || meta.vodId,
                mixpanelData,
                playZee5Content,
                playHotStarContent,
                deepLinkContent,
                isTVOD: meta?.isTVOD,
                tvodExpiry,
                lastWatch: lastWatch || {},
                openPopup,
                closePopup,
                openLoginPopup,
                hotstarPopup,
                redirectionHandling,
                handleGenericRedirectionPopup,
                playPrimeDeeplink,
                seriesList
            },
            meta?.type === 'seasonsType' ? 'seasonsType' : seriesType,
        );
    }, [contentType, history, lastWatch, mixpanelData, playHotStarContent, deepLinkContent, playZee5Content, tvodExpiry, hotstarPopup, playPrimeDeeplink]);

    return onPlayContent
}