import React from "react";
import { withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import "react-app-polyfill/ie11";
import "react-toastify/dist/ReactToastify.min.css";

import Header from "@components/Header";
import Footer from "@common/Footer";
import Modal from "@common/Modal";
import { openPopup, closePopup } from "@common/Modal/action";
import { mainRoutes, URL } from "@constants/routeConstants";
import {
    dropDownDismissalCases,
    isMobile,
    openTimeoutPopup,
    showAtvUpgradePopup,
    ftvWOEvents,
    getAnonymousId,
    handlePaymentSDKPrefetch,
    isWebSmallLinkPayment,
    isUserloggedIn,
    scrollToTop,
    handleOverflowOnHtml,
    getEnvironmentConstants,
    isMSalesPrevInfoExist,
    callLogOut,
    isPaymentRedirectURL,
    handleSilentLogout,
    initializeQoeSdk,
    safeNavigation,
    setUTMAppsflyerSession,
    getMixpanelSubscriptionAnalyticsData,
    showFireStickPopup,
    calculateBreadCrumbWidth,
    generateUuidV4String,
    checkPartnerSubscribed,
    showAppleRenewNudge,
    getLastActivityCapture,
} from "@utils/common";
import {
    accountDropDown,
    switchAccountDropDown,
    setSearch,
    notificationDropDown,
    fetchConfig,
    fetchHeaderData,
    getCategoriesList,
    categoryDropDown,
    isHideDownloadHeaderAction,
    fetchMediaReadyPolling,
    fetchFeatureListing,
    fetchAssignedExperiment,
} from "@components/Header/APIs/actions";
import { getProfileDetails } from "@containers/Profile/APIs/action";
import NetworkDetector from "@components/HOC/networkDetector";
import { deleteKey, getKey, setKey } from "@utils/storage";
import { closeMobilePopup } from "@containers/Languages/APIs/actions";
import {
    closeLoginPopup,
    setLoginManual,
    openLoginPopup,
} from "@containers/Login/APIs/actions";
import {
    BOTTOM_SHEET,
    MOBILE_BREAKPOINT,
    LOCALSTORAGE,
    WEB_SMALL_PAYMENT_SOURCE,
    SILENT_LOGIN_PLATFORM,
    SESSION_STORAGE,
    UTM_SOURCE,
} from "@constants";
import BottomSheet from "@common/BottomSheet";
import { hideSplash, loggedIn, fromLoginLoader } from "@src/action";
import {
    getCurrentSubscriptionInfo,
    getNotLoggedInPack,
    getWebPortalLink,
    openMiniSubscription,
    getPackListing,
    getWebPortalBackLink,
    checkFallbackFlow,
    getCurrentSubscriptionMyplanVerbiages,
} from "@containers/Subscription/APIs/action";
import Splash from "@common/Splash";
import HeaderHC from "@containers/HelpCenter/Common/HeaderHC";
import TopHeaderDownload from "@containers/HelpCenter/Common/TopHeaderDownload";
import {
    fetchWatchlistItems,
    fetchGamezopWatchlistItems,
} from "@containers/Watchlist/API/action";
import Analytics from "@components/HOC/analytics";
import ManagedApp from "@containers/Subscription/ManagedApp";
import createRoute from "@utils/createRoute";
import { showLangOrSubPopup, checkHomePage, accordingToMobileShowHideDownload, callBackToHideDownload, callMediaReadyConfig, fetchAccountPubnub, isSSR } from './MainCommon';
import RegionalNudge from "@containers/RegionalAppNudge";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import { PROVIDER_NAME } from "@utils/constants/playerConstant";
import { pubnub } from "@config/bootup";
import { setAppLaunchCounter } from "@containers/Home/APIs/actions";
import ProActiveChat from "@containers/HelpCenter/ProActiveChat";

import { getAppFooterList } from "./APIs/actions";
import { FOOTER_TYPE } from "./APIs/constants";
import OrganizationSchema from "@common/OrganizationSchema";
import SoftwareApplicationSchema from "@common/SoftwareApplicationSchema";
import WebsiteSchema from "@common/WebsiteSchema";
import RenewNudge from "@containers/RenewNudge";
import { checkNudgeVisiblity } from "@containers/RenewNudge/common";
import { ActivateAppleNudge } from "@containers/AppleActivateNudge";

class Main extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            minContainerHeight: window.innerHeight - 250,
            windowContainerWidth: window.innerWidth,
            isSubscriptionCallFinished: false,
        };
    }

    componentDidMount = async () => {
        this.onAppLoad();
        window.addEventListener("popstate", this.handledBingeTrial);
    };

    onAppLoad = async () => {
        if (window.location.pathname.toLowerCase() === `/${URL.DISCOUNT}`) {
            // to take user back from offers domain to actual domain for further discount activities
            window.location.replace(
                `${getEnvironmentConstants().ENV_URL}/${URL.SUBSCRIPTION_DISCOUNT}`
            );
        } else {
            await getLastActivityCapture();
            // Check if the user is logged in
            await this.loadData();
            accordingToMobileShowHideDownload(this);
            this.handleBingeAppLaunch();

            let paramData = new URLSearchParams(this.props.location.search);
            let status = paramData.get("status");
            let utmSource = paramData.get(UTM_SOURCE);
            let anyWhereUser = paramData.get("subscription");

            if (status === "login" && !anyWhereUser) {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = this.onBackBtnCallManageAppAPI;
            } else if (
                !!sessionStorage.getItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW)
            ) {
                // User is not allowed to access other routes if it is a binge trial flow
                safeNavigation(this.props.history, `/${URL.BINGE_TRIAL}`);
            } else if (
                this.props.history?.location?.pathname?.includes(URL.BINGE_TRIAL)
            ) {
                sessionStorage.setItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW, true);
            } else if (
                utmSource !== null &&
                utmSource !== undefined &&
                !isEmpty(utmSource)
            ) {
                setUTMAppsflyerSession();
            }
        }
    };

    handleBingeAppLaunch = () => {
        //As per TSF-16920
        let appLaunch = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE.BINGE_APP_LAUNCH)
        );
        if (!appLaunch) {
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.BINGE_APP_LAUNCH);
            sessionStorage.setItem(SESSION_STORAGE.BINGE_APP_LAUNCH, true);
        }
    };

    handledBingeTrial = (e) => {
        e.preventDefault();
        if (
            !!sessionStorage.getItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW) ||
            this.props.history?.location?.pathname?.includes(URL.BINGE_TRIAL)
        ) {
            safeNavigation(this.props.history, `/${URL.BINGE_TRIAL}`);
        }
    };

    onBackBtnCallManageAppAPI = () => {
        let paramData = new URLSearchParams(this.props.location.search);
        let cartId = paramData.get("cartId");

        this.props.getWebPortalBackLink(cartId);
    };

    componentDidUpdate = async (prevProps, prevState) => {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
            isSilentLogout = JSON.parse(getKey(LOCALSTORAGE.IS_SILENT_LOGOUT)),
            ismSalesPaymentRedirectURLBack =
                isPaymentRedirectURL(this.props.location) && isSilentLogout,
            showTPFibreWelcomeMsg =
                isUserloggedIn() && get(this.props.currentSubscription, "showFibreMsg");

        if (prevState.windowContainerWidth !== this.state.windowContainerWidth) {
            if (this.state.windowContainerWidth > MOBILE_BREAKPOINT) {
                this.props.showLoginPopup && this.props.closeLoginPopup();
            } else {
                this.props.closePopup();
            }
        }

        if (this.props.location !== prevProps.location) {
            calculateBreadCrumbWidth();
            scrollToTop();
            let deviceRemoved =
                JSON.parse(getKey(LOCALSTORAGE.DEVICE_REMOVED)) === true;
            deviceRemoved && openTimeoutPopup(this.props.history);

            let ftvWoGenerated = get(
                this.props.location.state,
                "ftvWoGenerated",
                false
            );

            if (
                prevProps.location.pathname === `/${URL.FIRE_TV_INSTALLATION}` &&
                !ftvWoGenerated
            ) {
                ftvWOEvents(false);
            }

            if (
                (prevProps?.location?.pathname === `/${URL.SUBSCRIPTION_TRANSACTION}` ||
                    prevProps?.location?.pathname === `/${URL.BALANCE_INFO}`) &&
                !this.props.history?.location?.state?.isRecharge
            ) {
                this.trackMixpanelOnPaymentExitFlow();
            }
        }

        if (
            prevProps &&
            this.props &&
            (!isEqual(
                get(this.props, "newUserDetail.subscriberId"),
                get(prevProps, "newUserDetail.subscriberId")
            ) ||
                !isEqual(
                    get(this.props, "existingUserDetail.subscriberId"),
                    get(prevProps, "existingUserDetail.subscriberId")
                )) &&
            userInfo.accessToken
        ) {
            await this.props.fetchAssignedExperiment();
            this.props.fetchWatchlistItems(false, false, true);
            this.props.fetchGamezopWatchlistItems();
            await this.loggedInUserJourney();
        }

        if (
            this.props.location !== prevProps.location &&
            (this.props.modalStatus || !isEmpty(this.props.miniSubscription)) &&
            !ismSalesPaymentRedirectURLBack &&
            !showTPFibreWelcomeMsg
        ) {
            !this.props.stopClosingOnUrlChange && this.props.closePopup();
            this.props.openMiniSubscription();
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleOnResize);
        window.removeEventListener("storage", this.handleLocalStorageUpdate);
        window.removeEventListener("touchstart", this.handleBouncingMobile);
        this.timerPolling = null;
        window.clearInterval(this.timerPolling);
        window.removeEventListener("popstate", this.handledBingeTrial);
        window.onpopstate = () => { };
        // this.clearMediaReadyInterval();
    }

    loadData = async () => {
        let {
            location: { pathname },
            fetchConfig,
            fetchHeaderData,
            getCategoriesList,
            getCurrentSubscriptionInfo,
            checkFallbackFlow,
            getAppFooterList,
            setAppLaunchCounter,
            getCurrentSubscriptionMyplanVerbiages,
            fetchAssignedExperiment,
            fetchFeatureListing,
        } = this.props;
        let isSilentLogout = JSON.parse(getKey(LOCALSTORAGE.IS_SILENT_LOGOUT));
        let recordAppLaunch = JSON.parse(getKey(LOCALSTORAGE.RECORD_APP_LAUNCH));

        if (this.props.history?.location?.pathname?.includes(URL.COUPON_REDEEM)) {
            isUserloggedIn() && await callLogOut(false, this.props.history, false);
        }
        
        isSilentLogout && handleSilentLogout();
        this.addEventListeners();
        await this.logOutPreviousMSalesUser();
        await fetchConfig(true);
        await fetchHeaderData();
        await getCategoriesList();
        await getAnonymousId();
        await checkFallbackFlow();
        await fetchFeatureListing();
        // await this.mediaReadyFlow();
        await getAppFooterList(FOOTER_TYPE.GLOBAL);
        if (isUserloggedIn()) {
            await initializeQoeSdk(); //initialize qoe sdk at every page refresh
            await getCurrentSubscriptionInfo();
            await getCurrentSubscriptionMyplanVerbiages();
            await fetchAccountPubnub();
            await fetchAssignedExperiment();
            (recordAppLaunch ||
                recordAppLaunch === undefined ||
                recordAppLaunch === null) &&
                (await setAppLaunchCounter(false));
            this.setState({
                isSubscriptionCallFinished: true,
            });
            await this.loggedInUserJourney();
            handlePaymentSDKPrefetch();
            this.firestickPopupFrequency();
            checkNudgeVisiblity();
            showAppleRenewNudge(location);

        } else {
            this.setState({
                isSubscriptionCallFinished: true,
            });
        }
        let deviceRemoved = JSON.parse(getKey(LOCALSTORAGE.DEVICE_REMOVED)) === true;
        !isSSR() && await showLangOrSubPopup(this);
        this.deleteTickTickParam();
        deviceRemoved && openTimeoutPopup(this.props.history);
        pathname && setKey(LOCALSTORAGE.CURRENT_PATH, pathname);
        deleteKey(LOCALSTORAGE.JWT_TOKEN);
    };

    //TSF-21940
    firestickPopupFrequency = () => {
        const { history, location, configResponse } = this.props;
        let fsFrequency = JSON.parse(getKey(LOCALSTORAGE.FS_LAUNCH_FREQUENCY)) || 0,
            fsDismissFrequency =
                JSON.parse(getKey(LOCALSTORAGE.FS_DISMISS_FREQUENCY)) || 0,
            fsLaunchFrequency =
                get(configResponse, "data.app.FTVPopUp.web.launchFrequency") || 3,
            ftvPopupDismissFrequency =
                get(configResponse, "data.app.FTVPopup.web.ftvPopupDismissFrequency") ||
                5,
            cartId = !!new URLSearchParams(location?.search).get("cartId");
        setKey(LOCALSTORAGE.FS_LAUNCH_FREQUENCY, ++fsFrequency);

        if (
            [
                `/${URL.HOME}`,
                `/${URL.MOVIES}`,
                `/${URL.TV_Shows}`,
                `/${URL.SPORTS}`,
                `/${URL.LIVE_TV}`,
                `${URL.DEFAULT}`,
            ].includes(location?.pathname) &&
            fsFrequency % fsLaunchFrequency === 0 &&
            fsDismissFrequency < ftvPopupDismissFrequency &&
            !cartId
        ) {
            showFireStickPopup(history);
        }
    };

    logOutPreviousMSalesUser = async () => {
        let { history } = this.props;
        if (isMSalesPrevInfoExist(history)) {
            await callLogOut(false, history, false);
        }
    };

    deleteTickTickParam = () => {
        let { location, history } = this.props;
        let paramData = new URLSearchParams(this.props.location.search);
        if (paramData.has("tickTick")) {
            let liveRailId = paramData.get("liveRailId"),
                newState = liveRailId ? { railId: liveRailId } : {};
            paramData.delete("tickTick");
            paramData.delete("liveRailId");
            let newSearch = paramData.toString() || "";
            history.replace({
                pathname: location.pathname,
                search: newSearch,
                state: newState,
            });
        }
    };

    addEventListeners = () => {
        window.addEventListener("resize", this.handleOnResize);
        window.addEventListener("storage", this.handleLocalStorageUpdate);
        window.addEventListener("popstate", dropDownDismissalCases);
        window.onclick = (event) => {
            if (
                event.target &&
                (event.target.id === "app" ||
                    (event.target.parentElement &&
                        event.target.parentElement.id === "app"))
            )
                dropDownDismissalCases("closeCondition");
        };
        window.ontouchstart = (e) => {
            e.stopPropagation();
        };
        window.onmessage = (e) => {
            // this block is added to handle the scroll issue in case of S360 chatbot opened
            //to avoid the backgoriund scrolling issue over cahtbot we have stopped scrolling of main app
            if (e?.data?.chatBotClosed === "true") {
                isMobile.any() && handleOverflowOnHtml(true);
            }
        };
    };

    loggedInUserJourney = async () => {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
        let atvUpgradeKey = JSON.parse(getKey(LOCALSTORAGE.ATV_UPGRADE));
        let {
            location: { pathname },
            history,
            getProfileDetails,
            loggedIn,
        } = this.props;
        const urlArr = pathname.split("/");
        let webSmallPaymentJourney = isWebSmallLinkPayment(location);
        if (!webSmallPaymentJourney && !userInfo?.helpCenterSilentLogin) {
            await getProfileDetails(true);
        }
        !urlArr.includes(URL.PLAYER) &&
            atvUpgradeKey &&
            showAtvUpgradePopup(history);
        loggedIn(true);
    };
    handleLocalStorageUpdate = () => {
        let showAtvPopup = JSON.parse(getKey(LOCALSTORAGE.ATV_UPGRADE)) === true;
        showAtvPopup && showAtvUpgradePopup(this.props.history);
    };

    checkIfDeviceIsMobile = () => {
        let screenWidth = screen.width;
        let orientation =
            (screen.orientation || {}).type ||
            screen.mozOrientation ||
            screen.msOrientation;
        if (
            orientation === "portrait-primary" ||
            orientation === "portrait-secondary"
        ) {
            return screenWidth >= 319 && screenWidth <= MOBILE_BREAKPOINT;
        } else {
            return screenWidth >= 319 && screenWidth <= 892;
        }
    };

    showTopHeaderDownload = () => {
        let { location: { pathname }, isHideDownloadHeader, piMeta } = this.props;
        const urls = [URL.HELP_CENTER, URL.TRANSACTIONS, URL.SUBSCRIPTION_CAMPAIGN, URL.SUBSCRIPTION_DISCOUNT, URL.ACTIVATE, URL.BINGE_TRIAL, URL.SUBSCRIPTION_TRANSACTION, URL.SELECT_LANGUAGE, URL.COUPON_REDEEM];
        const urlArr = pathname.split("/");
        const shouldShowOnUrl = !urls.includes(urlArr[1] || pathname),
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
            isMxContent = [URL.PLAYER].includes(urlArr[1]) && piMeta?.provider?.toLowerCase() === PROVIDER_NAME.MX_PLAYER;

        return !isHideDownloadHeader && isMobile.any() && shouldShowOnUrl && !sourceIsMSales && !isMxContent;
    }

    handleOnResize = () => {
        this.setState({
            ...this.state,
            minContainerHeight: window.innerHeight - 250,
            windowContainerWidth: window.innerWidth,
        });
        accordingToMobileShowHideDownload(this);
    };

    handleLocalStorageUpdate = () => {
        let showAtvPopup = JSON.parse(getKey(LOCALSTORAGE.ATV_UPGRADE)) === true;
        showAtvPopup && showAtvUpgradePopup(this.props.history);
    };

    checkIfDeviceIsMobile = () => {
        let screenWidth = screen.width;
        let orientation =
            (screen.orientation || {}).type ||
            screen.mozOrientation ||
            screen.msOrientation;
        if (
            orientation === "portrait-primary" ||
            orientation === "portrait-secondary"
        ) {
            return screenWidth >= 319 && screenWidth <= MOBILE_BREAKPOINT;
        } else {
            return screenWidth >= 319 && screenWidth <= 892;
        }
    };

    showTopHeaderDownload = () => {
        let {
            location: { pathname },
            isHideDownloadHeader,
            piMeta,
        } = this.props;
        const urls = [
            URL.HELP_CENTER,
            URL.TRANSACTIONS,
            URL.SUBSCRIPTION_CAMPAIGN,
            URL.SUBSCRIPTION_DISCOUNT,
            URL.ACTIVATE,
            URL.BINGE_TRIAL,
            URL.SUBSCRIPTION_TRANSACTION,
            URL.SELECT_LANGUAGE,
        ];
        const urlArr = pathname.split("/");
        const shouldShowOnUrl = !urls.includes(urlArr[1] || pathname),
            sourceIsMSales =
                getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) ===
                WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
            isMxContent =
                [URL.PLAYER].includes(urlArr[1]) &&
                piMeta?.provider?.toLowerCase() === PROVIDER_NAME.MX_PLAYER;

        return (
            !isHideDownloadHeader &&
            isMobile.any() &&
            shouldShowOnUrl &&
            !sourceIsMSales &&
            !isMxContent
        );
    };

    getMainContainerClass = () => {
        const {
            location: { pathname },
        } = this.props;
        const urlArr = pathname.split("/");
        let isPlayerScreen = [URL.PLAYER, URL.TRAILER].includes(urlArr[1]),
            isCampaignPage = [
                URL.SUBSCRIPTION_CAMPAIGN,
                URL.SUBSCRIPTION_DISCOUNT,
                URL.BINGE_TRIAL,
            ].includes(urlArr[1]),
            hideTopMarginUrls = [
                URL.HELP_CENTER,
                URL.PLAYER,
                URL.TRAILER,
                URL.SUBSCRIPTION_CAMPAIGN,
                URL.SUBSCRIPTION_DISCOUNT,
                URL.BINGE_TRIAL,
                URL.ACTIVATE,
                URL.SELECT_LANGUAGE,
            ].includes(urlArr[1]);
        return `main-container bottom-margin
        ${!hideTopMarginUrls ? "top-margin" : ""} 
        ${checkHomePage(this) ? "home-page" : ""} 
        ${isPlayerScreen && this.showTopHeaderDownload() && "margin-player"} 
        ${isCampaignPage ? "campaign-page-subscription" : ""}`;
    };

    trackMixpanelOnPaymentExitFlow = () => {
        const previousSubscription = JSON.parse(
            getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS)
        );
        let paymentJourneyProperty = getMixpanelSubscriptionAnalyticsData(
            previousSubscription,
            " ",
            MIXPANEL
        );
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.PAYMENT_FLOW_EXIT, {
            [MIXPANEL.PARAMETER.EXIT_STATUS]: MIXPANEL.VALUE.BACKPRESSED,
            [MIXPANEL.PARAMETER.PAYMENT_METHOD]: MIXPANEL.VALUE.NOT_SELECTED,
            [MIXPANEL.PARAMETER.PAYMENT_STATUS]: MIXPANEL.VALUE.NOT_ATTEMPTED,
            ...paymentJourneyProperty,
        });
    };

    mediaReadyFlow = async () => {
        pubnub(false, true); // Pubnub initiate for media ready at every page refresh
        let durationForMRPolling = JSON.parse(
            getKey(LOCALSTORAGE.MEDIAREADY_CONFIG_CALLING_DURATION)
        );
        if (durationForMRPolling > 0) {
            this.mediaReadytInterval = setInterval(async () => {
                if (callMediaReadyConfig()) {
                    console.log("MR Polling: Call Media ready API");
                    await this.props.fetchMediaReadyPolling();
                }
            }, durationForMRPolling * 60000);
        }
    };
    clearMediaReadyInterval = () => {
        clearInterval(this.mediaReadytInterval);
    };

    getMainContainerClass = () => {
        const { location: { pathname } } = this.props;
        const urlArr = pathname.split("/");
        let isPlayerScreen = [URL.PLAYER, URL.TRAILER].includes(urlArr[1]),
            isCampaignPage = [URL.SUBSCRIPTION_CAMPAIGN, URL.SUBSCRIPTION_DISCOUNT, URL.BINGE_TRIAL].includes(urlArr[1]),
            hideTopMarginUrls = [URL.HELP_CENTER, URL.PLAYER, URL.TRAILER, URL.SUBSCRIPTION_CAMPAIGN, URL.SUBSCRIPTION_DISCOUNT, URL.BINGE_TRIAL, URL.ACTIVATE, URL.SELECT_LANGUAGE, URL.COUPON_REDEEM].includes(urlArr[1]);
        return `main-container bottom-margin
        ${(!hideTopMarginUrls) ? 'top-margin' : ''} 
        ${checkHomePage(this) ? 'home-page' : ''} 
        ${isPlayerScreen && this.showTopHeaderDownload() && 'margin-player'} 
        ${isCampaignPage ? 'campaign-page-subscription' : ''}
        ${(checkHomePage(this, this.props.isNewHeroBannerEnabled) && this.props.isNewHeroBannerEnabled) ? `new-banner-home ${this.props.isHideDownloadHeader ? 'margin-rm' : ''}` : ''}`;
    }

    render() {
        let {
            modal: { showModal },
            setSearch,
            accountDropDown,
            switchAccountDropDown,
            history,
            header,
            notificationDropDown,
            footer,
            configResponse,
            location: { pathname },
            categoriesDropdownVal,
            categoryDropDown,
            localFooterList,
            globalFooterList,
            currentSubscription,
        } = this.props;
        const urlArr = pathname.split("/");
        let {
            minContainerHeight,
            windowContainerWidth,
            isSubscriptionCallFinished,
        } = this.state;
        let showHelpCenterHeader =
            pathname === `/${URL.HELP_CENTER}` ||
            [
                URL.HC_DELETE_ACCOUNT,
                URL.HC_CONFIRM_DELETE,
                URL.HC_SECURITY_CHECK,
            ].includes(urlArr[2]),
            showAppHeader =
                !header &&
                ![
                    URL.HELP_CENTER,
                    URL.SUBSCRIPTION_CAMPAIGN,
                    URL.TRANSACTIONS,
                    URL.SUBSCRIPTION_DISCOUNT,
                    URL.BINGE_TRIAL,
                    URL.SELECT_LANGUAGE,
                ].includes(urlArr[1]),
            showFooter =
                !footer &&
                ![
                    URL.HELP_CENTER,
                    URL.TRANSACTIONS,
                    URL.SUBSCRIPTION_CAMPAIGN,
                    URL.SUBSCRIPTION_DISCOUNT,
                    URL.BINGE_TRIAL,
                    URL.SELECT_LANGUAGE,
                ].includes(urlArr[1]),
            handleProActiveChat =
                [URL.HELP_CENTER].includes(urlArr[1]) &&
                configResponse?.data?.config?.cms_constants?.proactiveChatEnabled,
            nudgeData =
                isUserloggedIn() &&
                !isEmpty(currentSubscription) &&
                !isEmpty(configResponse),
            showAppleNudge =
                isUserloggedIn() &&
                checkPartnerSubscribed(currentSubscription, "", PROVIDER_NAME.APPLE) &&
                isEmpty(urlArr[1]);

        return (
            <div>
                <OrganizationSchema />
                <SoftwareApplicationSchema />
                <WebsiteSchema />
                <Splash />
                <React.Fragment>
                    <>
                        <ManagedApp />
                        <RegionalNudge />
                        {nudgeData && <RenewNudge />}
                        {showAppleNudge && <ActivateAppleNudge />}
                        {handleProActiveChat && isUserloggedIn() && <ProActiveChat />}
                        {this.showTopHeaderDownload() && (
                            <TopHeaderDownload
                                callBackToHideDownload={callBackToHideDownload}
                            />
                        )}
                        <div
                            className={`${this.showTopHeaderDownload() && "top-download-header"
                                } ${`page-cls-${urlArr[1]}`}`}
                        >
                            {showHelpCenterHeader && (
                                <HeaderHC history={history} urlArr={urlArr} />
                            )}
                            {showAppHeader && <Header />}
                            <div
                                className={this.getMainContainerClass()}
                                onClick={() => {
                                    dropDownDismissalCases("closeCondition");
                                }}
                                style={{ minHeight: minContainerHeight }}
                            >
                                <ToastContainer
                                    autoClose={3000}
                                    hideProgressBar={true}
                                    style={{ color: "#000", background: "transparent" }}
                                    limit={1}
                                    position={
                                        isMobile.any()
                                            ? toast.POSITION.BOTTOM_CENTER
                                            : toast.POSITION.TOP_RIGHT
                                    }
                                />
                                {!isEmpty(this.props.miniSubscription) && (
                                    <BottomSheet
                                        big={true}
                                        type={BOTTOM_SHEET.MINI_SUBSCRIPTION}
                                    />
                                )}
                                {categoriesDropdownVal && windowContainerWidth <= 768 && (
                                    <BottomSheet big={true} type={BOTTOM_SHEET.CATEGORIES} />
                                )}
                                {this.props.showQrCodeScreen &&
                                    <BottomSheet big={true} type={BOTTOM_SHEET.QR_JOUNEY} />}
                                {isSubscriptionCallFinished && (
                                    <Switch>{createRoute(mainRoutes)}</Switch>
                                )}
                            </div>
                            {showModal && <Modal {...this.props.history} />}
                            {showFooter && (
                                <Footer
                                    configResponse={configResponse}
                                    setSearch={setSearch}
                                    accountDropDown={accountDropDown}
                                    notificationDropDown={notificationDropDown}
                                    history={history}
                                    switchAccountDropDown={switchAccountDropDown}
                                    categoryDropDown={categoryDropDown}
                                    pageFooterList={localFooterList}
                                    globalFooterList={globalFooterList}
                                />
                            )}
                        </div>
                    </>
                </React.Fragment>
            </div>
        );
    }
}

Main.propTypes = {
    commonContent: PropTypes.object,
    modal: PropTypes.object,
    history: PropTypes.object,
    setSearch: PropTypes.func,
    accountDropDown: PropTypes.func,
    switchAccountDropDown: PropTypes.func,
    notificationDropDown: PropTypes.func,
    getProfileDetails: PropTypes.func,
    header: PropTypes.bool,
    footer: PropTypes.bool,
    configResponse: PropTypes.object,
    location: PropTypes.object,
    fetchConfig: PropTypes.func,
    openPopup: PropTypes.func,
    anonymousUserId: PropTypes.string,
    fetchHeaderData: PropTypes.func,
    headerItems: PropTypes.array,
    closePopup: PropTypes.func,
    closeMobilePopup: PropTypes.func,
    getCategoriesList: PropTypes.func,
    categoriesDropdownVal: PropTypes.bool,
    closeLoginPopup: PropTypes.func,
    showLoginPopup: PropTypes.bool,
    newUserDetail: PropTypes.object,
    existingUserDetail: PropTypes.object,
    profileDetails: PropTypes.object,
    hideSplash: PropTypes.func,
    getCurrentSubscriptionInfo: PropTypes.func,
    loggedIn: PropTypes.func,
    categoryDropDown: PropTypes.func,
    isHideDownloadHeaderAction: PropTypes.func,
    isHideDownloadHeader: PropTypes.bool,
    fetchWatchlistItems: PropTypes.func,
    setLoginManual: PropTypes.func,
    loggedStatus: PropTypes.bool,
    modalStatus: PropTypes.bool,
    openLoginPopup: PropTypes.func,
    getNotLoggedInPack: PropTypes.func,
    openMiniSubscription: PropTypes.func,
    fromLoginLoader: PropTypes.func,
    isManagedApp: PropTypes.bool,
    checkFallbackFlow: PropTypes.func,
    fetchGamezopWatchlistItems: PropTypes.func,
    stopClosingOnUrlChange: PropTypes.bool,
    fetchMediaReadyPolling: PropTypes.func,
    mediaReadyPollingRes: PropTypes.object,
    setAppLaunchCounter: PropTypes.func,
    getCurrentSubscriptionMyplanVerbiages: PropTypes.func,
    fetchAssignedVariant: PropTypes.func,
};

const mapStateToProps = (state) => ({
    modal: state.modal,
    header: get(state.commonContent, "header"),
    footer: get(state.commonContent, "footer"),
    configResponse: get(state.headerDetails, "configResponse"),
    anonymousUserId: get(state.headerDetails, "anonymousUserData.anonymousId"),
    headerItems: get(state, "headerDetails.headerItems.items"),
    showLoginPopup: get(state.loginReducer, 'showLoginPopup'),
    newUserDetail: get(state.loginReducer, 'newUser.data'),
    existingUserDetail: get(state.loginReducer, 'existingUser.data'),
    profileDetails: get(state.profileDetails, 'userProfileDetails'),
    categoriesDropdownVal: state.headerDetails.categoriesDropdown,
    isHideDownloadHeader: state.headerDetails.isHideDownloadHeader,
    loggedStatus: get(state.commonContent, 'loggedStatus'),
    modalStatus: get(state.modal, 'showModal'),
    miniSubscription: get(state.subscriptionDetails, 'miniSubscription'),
    showQrCodeScreen: get(state.qrLoginReducer, 'showQrCodeScreen'),
    isManagedApp: get(state.headerDetails, "isManagedApp"),
    piMeta: get(state.PIDetails.data, "meta"),
    stopClosingOnUrlChange: get(state.modal, 'stopClosingOnUrlChange'),
    mediaReadyPollingRes: get(state.headerDetails, "mediaReadyPollingRes.data"),
    liveMetaData: get(state.PIDetails.liveDetail, "data"),
    localFooterList: get(state.mainReducer, 'localFooterList.data'),
    globalFooterList: get(state.mainReducer, 'globalFooterList.data'),
    currentSubscription: get(state.subscriptionDetails, 'currentSubscription.data'),
    isNewHeroBannerEnabled: get(state.headerDetails, "isNewHeroBannerEnabled"),
    showQrCodeScreen: get(state.qrLoginReducer, 'showQrCodeScreen'),
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getProfileDetails,
                setSearch,
                accountDropDown,
                switchAccountDropDown,
                notificationDropDown,
                fetchConfig,
                openPopup,
                fetchHeaderData,
                closePopup,
                closeMobilePopup,
                getCategoriesList,
                closeLoginPopup,
                hideSplash,
                getCurrentSubscriptionInfo,
                loggedIn,
                categoryDropDown,
                isHideDownloadHeaderAction,
                fetchWatchlistItems,
                setLoginManual,
                openLoginPopup,
                getNotLoggedInPack,
                openMiniSubscription,
                getPackListing,
                fromLoginLoader,
                getWebPortalLink,
                getWebPortalBackLink,
                checkFallbackFlow,
                fetchGamezopWatchlistItems,
                fetchMediaReadyPolling,
                getAppFooterList,
                setAppLaunchCounter,
                getCurrentSubscriptionMyplanVerbiages,
                fetchFeatureListing,
                fetchAssignedExperiment,
            },
            dispatch
        ),
    };
};

export default NetworkDetector(
    Analytics(withRouter(connect(mapStateToProps, mapDispatchToProps)(Main)))
);
